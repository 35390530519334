@import "react-big-calendar/lib/sass/styles";

.rbc-off-range-bg {
  background-color: rgba(231, 231, 231, 0.499);
}
.event-wrapper {
  background-color: #c9f7f5;
  color: black;
  width: max-content;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 4px;
  border-radius: 4px;
  padding: 1px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.slots {
    background-color: transparent;
    color: gray;
    cursor: not-allowed;
  }
  &.active {
    color: black;
    cursor: pointer;
  }
}
.rbc-toolbar {
  font-size: 0.8rem !important;
  .rbc-toolbar-label {
    font-size: 1.2rem !important;
    text-align: right !important;
    text-transform: uppercase;
  }
}
.rbc-day-bg {
  span {
    background-color: rgba(211, 211, 211, 0.221);
    border-radius: 4px;
    padding: 1px;
    font-size: 0.9rem;
    margin: 6px;
  }

  &:hover {
    background-color: #bae1ff;
    cursor: pointer;
  }
}
