.docs-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .doc {
    .docs-body {
      .title {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        // padding: 10px 0;
        font-weight: bold;
        text-transform: capitalize;
      }
      .img {
        img {
          cursor: pointer;
          object-fit: cover;
          border-radius: 10px;
          max-width: -webkit-fill-available;
        }
      }
    }
    .docs-footer {
      margin: 10px 0;
    }
  }
}
