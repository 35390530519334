.slot-card {
  padding: 15px;
  margin: 10px;
  border-radius: 5px;
  font-weight: 500;
  color: white;
  background-color: lightblue;
  // color: white;
  &-body {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }
  &.booked{
    background-color: lightcoral;
  }
}
