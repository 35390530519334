.skeleton{
    background: #ddd;
    margin: 10px 0;
    border-radius: 4px;
}

$skeleton-loading-animation-time: 1.3s !default;
@keyframes ssc-loading {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}
%ssc-animation {
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        animation: ssc-loading $skeleton-loading-animation-time infinite;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: 1;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
    }
}

.skeleton.text{
    width: 100%;
    height: 6px;
}
.skeleton.title{
    width: 70%;
    height: 15px;
    margin-top: 15px;

    @extend %ssc-animation;
}
.skeleton.tab{
    width: 10%;
    height: 30px;
    margin-top: 15px;
    margin-right: 15px;

    @extend %ssc-animation;
}
.skeleton.headerr{
    width: 70%;
    height: 30px;
    margin-top: 15px;

    @extend %ssc-animation;
}
.skeleton.wraper{
    margin: 20px auto;
    padding: 10px;
    background: #ECECEC;
    position: relative;
  
}
.skeleton-wraper{
    // margin: 20px auto;
    padding: 10px;
    background: #ffffff;
    position: relative;
    border-radius: 3px;
  
}
.skeleton.wraper.light{
    background: #f2f2f2;
  
}
.skeleton.wraper.dark{
    background: #444;
  
}
.skeleton.wraper.dark.skeleton{
    background: #777;
  
}
.shimmer-wraper{    
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;    
    transform: translateX(-100%);
    animation: loading 5.0s infinite;
  
}
.shimmer{
    width: 50%;
    height: 100%;    
    background: rgba(255, 255, 255, 0.5);
    transform: skewX(-20deg);
    
  
}
@keyframes loading {
    /* 100% {
        transform: translateX(100%);
      } */
    0%{ transform: translateX(-150%);}
    20%{ transform: translateX(-60%);}
    50%{ transform: translateX(-80%);}
    80%{ transform: translateX(100%);}
    100%{ transform: translateX(150%);}
}
.skeleton-table{
    table{
        td,th{
            border-top: none !important;
            border-bottom: 1px solid #EBEDF3;
        }
    }
}