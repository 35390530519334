.banner {
  width: 100%;
  max-height: 500px;
  // position: relative;
  img {
    width: auto;
    height: auto;
    max-height: 500px;
    max-width: 700px;

    border-radius: 1.1rem;
    // display: block;
    // position: relative;
  }
}
