//
// Aside dark theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

.aside {
	background-color: #000000;

	// Aside Menu
	.aside-menu {
		// Scrollbar
		background-color: #000000;
		@include perfect-scrollbar-theme(#637099);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, dark);
