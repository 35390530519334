.chat-container {
  position: fixed;
  bottom: -5px;
  right: 20px;
  z-index: 100;
  display: flex;
  align-items: flex-end;
  gap: 15px;
  .chat {
    width: 330px;
    background-color: black;
    //   padding: 10px;
    //   border-radius: 10px 10px 0 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    //   border: 1px solid gray;
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 10px 15px;
      cursor: pointer;
      .title {
        font-size: 1rem;
        font-weight: 600;
        // text-transform: capitalize;
      }
    }
    &-body {
      display: block;

      min-height: 300px;
      background-color: #f7f9fb;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .message-container {
        height: 320px;
        overflow: auto;
        padding: 15px;
        .message {
          display: flex;
          .msg-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
            p {
              padding: 8px;
              color: white;
              background-color: lighten($primary, 40%);
              margin-bottom: 0;
            }
          }

          &-left {
            justify-content: flex-start;
            .msg-group {
              align-items: flex-start;
              p {
                border-radius: 0 5px 5px 5px;
              }
            }
          }
          &-right {
            justify-content: flex-end;
            .msg-group {
              align-items: flex-end;
              p {
                border-radius: 5px 5px 0px 5px;
                background-color: $primary;
              }
            }
          }
        }
      }
      .footer {
        .action {
          display: flex;
          justify-content: center;
          gap: 5px;
          align-items: center;
          button {
            width: 100%;
          }
        }
        .input {
          padding: 15px;
          background-color: white;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          gap: 5px;
          input {
            outline: none;
            // border-radius: 5px;
            border: none;
            width: 100%;
          }
          // input:disabled {
          //   border-radius: 5px;
          //   background-color: #dddddd;
          // }
        }
      }
      &.hide {
        display: none;
      }
    }
    // &-footer {
    //   background-color: gray;
    // }
  }
}