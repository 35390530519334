//
// Main Styles
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import "~bootstrap/scss/bootstrap";
@import "vendors/react/material-ui/general";

//
// Main Style
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "~bootstrap/scss/bootstrap";

// 3: Components
@import "components/utilities", "components/burger-icon", "components/card",
  "components/wizard", "components/type", "components/breadcrumb",
  "components/nav", "components/alert", "components/buttons", "components/code",
  "components/dropdown", "components/forms", "components/grid",
  "components/modal", "components/progress", "components/table",
  "components/popover", "components/tooltip", "components/accordion",
  "components/datatable", "components/checkbox", "components/radio",
  "components/switch", "components/option", "components/dialog",
  "components/strikethrough", "components/separator", "components/image-input",
  "components/label", "components/loader", "components/symbol",
  "components/pulse", "components/page-loader", "components/quick-search",
  "components/scrolltop", "components/spinner", "components/sticky-toolbar",
  "components/svg-icon", "components/svg", "components/timeline",
  "components/timeline-2", "components/timeline-3", "components/timeline-4",
  "components/timeline-5", "components/timeline-6", "components/overlay",
  "components/wave", "components/list", "components/bullet", "components/navi",
  "components/ribbon", "components/offcanvas", "components/toggle",
  "components/calender", "components/chat", "components/docs-card",
  "components/image-dialog", "components/slot-cards";

// 4: Plugins
@import "vendors/plugins/formvalidation", "vendors/plugins/dropzone",
  "vendors/plugins/perfect-scrollbar", "vendors/plugins/prismjs";

// 5: Layout
@import "layout/init";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
@import "pages/approval/banners_action";

.login-logo {
  // background-color: rgb(255, 255, 255);
  padding: 0px 10px;
  width: auto;
  max-height: 90px;
}
.btn-group {
  // margin: 0 10px;
  .btn {
    padding: 5px 10px !important;
    font: 0.8rem;
    font-weight: bolder !important;
  }
}
.header-input {
  .MuiOutlinedInput-input {
    padding: 11.5px 14px 11.5px 0;
  }
}

.user-avtar {
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 10px;
  .symbol-label {
    // background-color: lightblue;
    box-shadow: 0px 0px 1px 2px #e6e6e6;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    object-fit: contain;
    // padding: 5px;
    display: inline-block;
    cursor: pointer;
    svg {
      color: lightblue;
    }
    &:hover {
      box-shadow: 0px 0px 1px 5px #e6e6e6;
    }
  }
}
.symbol-text {
  font-size: x-large;
  font-weight: bolder !important;
}
.sticky-sidebar {
  position: sticky;
  top: 9%;
  height: max-content;
}

.banner-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: small;

  gap: 20px;
  label {
    margin: 0;
    color: gray;
  }
  a {
    color: #180f77;
  }
  .icon {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
.no-data-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
}
.sett-grid {
  display: grid;
  // grid-template-columns: 55% 45%;
  gap: 20px;
}

.fixed-body {
  height: 75vh;
  overflow: auto;
}

.header {
  background-color: #180f77 !important;
  color: #ffffff !important;
}
.col-field {
  background-color: rgb(236, 236, 236);
  max-width: 150px;
  padding: 0.35rem 1rem;
  height: calc(1em + 1rem + 2px);
  font-size: 0.9em;
}
.filter-label {
  display: contents;
}
.meet-image {
  a {
    width: 100%;
    img {
      object-fit: cover;
      border-radius: 10px;
      max-width: -webkit-fill-available;
    }
  }
}
.react-datepicker {
  width: max-content;
}
.react-datepicker__monthPicker {
  font-size: 1rem;
  padding: 10px;
  .react-datepicker__month-text {
    width: 6rem !important;
  }
  .react-datepicker__month-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.aside-toggle {
  svg g [fill] {
    fill: #ffffff !important;
  }
}
